@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 14px;
}

@media (max-width: 1280px) {
  html, body, #root {
    font-size: 12px;
  }
}

@media (max-width: 425px) {
  .spacer-header {
    display: none;
  }

  .header {
    justify-content: space-between;
  }
}